import { useEffect, useState } from "react"
import { BuilderComponent, builder,} from '@builder.io/react'
builder.init(typeof process.env.REACT_APP_ACCESS_BUILDER_IO === "undefined" ? "" : process.env.REACT_APP_ACCESS_BUILDER_IO);
    
  export const WaitlistSection1 = () => {
  
    const [builderContentJson, setBuilderContentJson] = useState({data: {title: "", keywords: "", description: ""}})
  
    useEffect(() => { 
      builder.get('waitlist-section-1', { url: window.location.pathname })
        .promise().then(setBuilderContentJson)
    }, [])
  
    return <BuilderComponent model="waitlist-section-1" content={builderContentJson} />
  }