import React, { FC, useState, useEffect } from "react";
import CardFeaturedPost from "./CardFeaturedPost";
import CardPost from "./CardPost";
import AirTableBlog from "model/AirTableBlog";
export interface SectionMagazineProps {
}

const SectionMagazine: FC<SectionMagazineProps> = () => {

  const [posts, setPosts] = useState([]);
  const [featured, setFeatured] = useState({});

  useEffect(() => { 
    getPosts()
   }, []);

   const getPosts = async () => {
    const _AirtableBlog = new AirTableBlog();
    const posts = await _AirtableBlog.getFeaturedPosts();
    //Loop the post and find the featured
    for(const p of posts){
      if(p.isFeatured === "true"){
        setFeatured(p);
        const index = posts.findIndex((x: { id: string; }) => x.id === p.id)
        posts.splice(index, 1);
        break
      }
    }
    setPosts(posts);
  };
  return (
    <div className="nc-SectionMagazine5">
      <div className="grid lg:grid-cols-2 gap-6 md:gap-8">
        <CardFeaturedPost featured={featured} />
        <div className="grid gap-6 md:gap-8">
          {posts.map((item: any, index: number) => (
            <CardPost key={index} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};
export default SectionMagazine;