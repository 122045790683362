import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link } from "react-router-dom";
import SocialsShare from "shared/SocialsShare/SocialsShare";
import BlogPostCardMeta from "components/PostCardMeta/BlogPostCardMeta";

export interface CardFeaturedPostProps {
  className?: string;
  featured: any
}

const CardFeaturedPost: FC<CardFeaturedPostProps> = ({ className = "h-full", featured }) => {
  return (
    <div
      className={`nc-Card12 group relative flex flex-col ${className}`}
      data-nc-id="Card12"
    >
      <Link
        to={`/blog/${featured.slug}`}
        className="block flex-shrink-0 flex-grow relative w-full h-0 aspect-w-4 aspect-h-3 rounded-3xl overflow-hidden"
      >
        <NcImage
          src={featured.featured}
          containerClassName="absolute inset-0"
          alt={featured.title}
        />
      </Link>

      <SocialsShare className="absolute hidden md:grid gap-[5px] right-4 top-4 opacity-0 z-[-1] group-hover:z-10 group-hover:opacity-100 transition-all duration-300" />

      <div className=" mt-8 pr-10 flex flex-col">
        <h2
          className={`nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 transition-colors text-lg sm:text-2xl`}
        >
          <Link
            to={`/${featured.slug}`}
            className="line-clamp-2 capitalize"
            title={"title"}
          >
            {featured.title}
          </Link>
        </h2>
        <span className="hidden sm:block mt-4 text-neutral-500 dark:text-neutral-400">
          <span className="line-clamp-2">
            <div
              dangerouslySetInnerHTML={{ __html: featured.description || "" }}
            ></div>
          </span>
        </span>
        <BlogPostCardMeta
          author={featured.author}
          date={featured.date}
          avatar={featured.avatar}
          className="mt-5"
        />
      </div>
    </div>
  );
};

export default CardFeaturedPost;
