import React, { useEffect, useState } from "react"
import { builder } from '@builder.io/sdk'
import NavigationItem from "./NavigationItem";
import { NavItemType } from "shared/Navigation/NavigationItem";


function Navigation() {

  const [nav, setNav] =  useState<NavItemType []>([ {
    id: "11",
    href: "/",
    name: "Menu",
    type: "dropdown",
    children: [],
  }])


  useEffect(() => {
    async function fetchContent() {
      let data = await builder.get('main-menu').promise().then(({ data }) => {
        return data
      })
      nav[0].children = data.childMenus;
      setNav(nav);
    }
    fetchContent();
  });


  return (
    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
      {nav.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}
export default Navigation;