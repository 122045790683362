import Airtable from "airtable";
import BlogPost from "interfaces/BlogPost";
export default class AirTableBlog {
  readonly apiKey: string | undefined;
  readonly base: string;
  readonly table: string | undefined;
  readonly _table: any | undefined;
  readonly _Airtable: Airtable;
  readonly _AirtableBase: any;

  constructor(
    ...args:
      | []
      | [apiKey: string | undefined, base: string, table: string | undefined]
  ) {
    this.apiKey = args[0] || process.env.REACT_APP_AIRTABLE_KEY;
    this.base = args[1] || process.env.REACT_APP_AIRTABLE_BASE || "";
    this.table = args[2] || process.env.REACT_APP_AIRTABLE_TABLE;
    this._Airtable = new Airtable({ apiKey: this.apiKey });
    this._AirtableBase = this._Airtable.base(this.base);
    this._table = this._AirtableBase.table(this.table);
  }
  getFeaturedPosts = async () => {
    const posts = await this._table?.select({sort: [{field: 'date', direction: 'desc'}] ,filterByFormula: 'AND({isFeatured}="true", {status}="published")'}).all();

    const posts_ = posts.map((post: any) => {
      const BlogPost: BlogPost = {
        id: post.id,
        title: post.fields.title,
        content: post.fields.content,
        date: post.fields.date,
        status: post.fields.status,
        author: post.fields.author.name,
        avatar: post.fields.avatar[0].url,
        slug: post.fields.slug,
        featured: post.fields.featured[0].url,
        isFeatured: post.fields.isFeatured,
        description: post.fields.description,
        tag: post.fields.tag,
        metaDescription: post.fields.metaDescription,
        metaKeywords: post.fields.metaKeywords
      }
      return BlogPost;
    });

    return posts_;
  };
  getPosts = async () => {
    const posts = await this._table?.select({sort: [{field: 'date', direction: 'desc'}] ,filterByFormula: 'AND({isFeatured}="false", {status}="published")'}).all();

    const posts_ = posts.map((post: any) => {

      const BlogPost: BlogPost = {
        id: post.id,
        title: post.fields.title,
        content: post.fields.content,
        date: post.fields.date,
        status: post.fields.status,
        author: post.fields.author.name,
        avatar: post.fields.avatar,
        slug: post.fields.slug,
        featured: post.fields.featured,
        isFeatured: post.fields.isFeatured,
        description: post.fields.description,
        tag: post.fields.tag,
        metaDescription: post.fields.metaDescription,
        metaKeywords: post.fields.metaKeywords
      }

      return BlogPost;
    });

    return posts_;
  };
  getPost = async (slug: string | undefined) => {
    if(typeof slug !== "undefined"){
      const posts = await this._table?.select({filterByFormula: `AND({slug}="${slug}", {status}="published")`}).all();
      const post = posts.map((post: any) => {
        const BlogPost: BlogPost = {
          id: post.id,
          title: post.fields.title,
          content: post.fields.content,
          date: post.fields.date,
          status: post.fields.status,
          author: post.fields.author.name,
          avatar: post.fields.avatar[0].url || null,
          slug: post.fields.slug,
          featured: post.fields.featured[0].url || null,
          isFeatured: post.fields.isFeatured,
          description: post.fields.description,
          tag: post.fields.tag,
          metaDescription: post.fields.metaDescription,
          metaKeywords: post.fields.metaKeywords
        }
        return BlogPost;
      });
      return post[0];
    }
    return {}
  };
}
