import React, { FC, useContext, useState, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Helmet } from "react-helmet";
import NcModal from "shared/NcModal/NcModal";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcImage from "shared/NcImage/NcImage";
import switchCamImg from "images/icons/switch-camera-color.png";
import { UserContext } from "../context/userContext";
import {Html5Qrcode} from "html5-qrcode";
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export interface ScanProps {
  className?: string;
}


const toastConfig: ToastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
}

const Scan: FC<ScanProps> = ({ className = "" }) => {

  const { user } = useContext(UserContext);
  
  const [showModal, setShowModal] = useState(false);
  const [cameraId, setCameraId] = useState<any>("");
  const [device, setDevice] = useState<any>(0);
  const [data, setData] = useState<any>("");
  const [devices, setDevices] = useState<Array<any>>([]);
  const [scannedTitle, setScannedTitle] = useState<string>("");
  const [scannedMsg, setScannedMsg] = useState<string>("");
  const [scannedImg, setScannedImg] = useState<any>("");
  const [html5QrCode, setHtml5QrCode] = useState<Html5Qrcode>();


  function toggleDevice(){
    setDevice(device === 0 ? 1 : 0)
  }
  useEffect(() => { 
    toast.promise(Html5Qrcode.getCameras().then(devices => {
      /**
       * devices would be an array of objects of type:
       * { id: "id", label: "label" }
       */
      if (devices && devices.length) {
        setDevices(devices);
        setCameraId(devices[device].id);
        // .. use this to start scanning.
        const html5QrCode = new Html5Qrcode(/* element id */ "reader");
        setHtml5QrCode(html5QrCode);
      }
    }).catch(err => {
      // handle err
    }), {
      pending: "Readying Camera",
    }, toastConfig);

    return () => {
      if (cameraId !== "" && typeof html5QrCode !== "undefined") {
        html5QrCode
          .stop()
          .then((ignore: any) => {
            // QR Code scanning is stopped.
          })
          .catch((err: any) => {
            // Stop failed, handle it.
            console.error(err);
          });
      }
    };
  }, []);

  useEffect(() => {
    if (cameraId !== "" && typeof html5QrCode !== "undefined") {
      toast.promise(
        html5QrCode
          .start(
            cameraId,
            {
              fps: 10, // Optional, frame per seconds for qr code scanning
              qrbox: { width: 250, height: 250 }, // Optional, if you want bounded box UI
            },
            (decodedText: any, decodedResult: any) => {
              // do something when code is read
              console.info("response", { decodedResult, decodedText });
              setData(JSON.parse(decodedText));
              setShowModal(true);
            },
            (errorMessage: any) => {
              // parse error, ignore it.
              //console.info("errorMessage", errorMessage)
            }
          )
          .catch((err: any) => {
            // Start failed, handle it.
          }),
        {
          pending: "Loading Scanner",
        },
        toastConfig
      );
    }
    return () => {

    };
  }, [cameraId, html5QrCode]);

  useEffect(() => {
    if (devices.length !== 0) {
      setCameraId(devices[device].id);
    }
    return () => {};
  }, [device, devices]);


  const renderContent = () => {
    return (
      <form action="#">
        <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
          {scannedTitle}
        </h2>
        <span className="text-sm">
          {scannedMsg}
        </span>

        <div className="p-5 border bg-white dark:bg-neutral-300 border-neutral-200 dark:border-neutral-700 rounded-xl flex items-center justify-center mt-4">
        <NcImage className="w-40" src={scannedImg} />
        </div>

        <div className="mt-5 space-x-3">
          <ButtonPrimary type="submit">Done</ButtonPrimary>
          <ButtonSecondary type="button">Cancel</ButtonSecondary>
        </div>
      </form>
    );
  };

  return (
    <div
      className={`nc-PageConnectWallet ${className}`}
      data-nc-id="PageConnectWallet"
    >
      <Helmet>
        <title>Connect Wallet || NFT React Template</title>
      </Helmet>
      <div className="container">
        <div className="my-12 sm:lg:my-16 lg:my-24 max-w-3xl mx-auto space-y-8 sm:space-y-10">
          {/* HEADING */}
          <div className="max-w-2xl">
            <h1 className="text-3xl sm:text-4xl font-semibold">Scan Code</h1>
            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              Scan a QR code of a user.
            </span>
          </div>
          <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
          <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
            {cameraId !== "" ? (
              <div className="space-y-3">
                <div
                  typeof="button"
                  tabIndex={0}
                  className="relative rounded-xl hover:shadow-lg hover:bg-neutral-50 border 
                border-neutral-200 dark:border-neutral-700 px-3 sm:px-5 py-4 cursor-pointer flex 
                focus:outline-none focus:shadow-outline-blue focus:border-blue-500 dark:bg-neutral-800 
                dark:text-neutral-100 dark:hover:bg-neutral-900 dark:hover:text-neutral-200"
                >
                  <div className="flex items-center w-full" id="reader"></div>
                  <div className="absolute top-2.5 left-2.5 z-10 flex items-center space-x-2">
                    <button
                      className={`bg-black/50 px-3.5 h-10 flex items-center justify-center rounded-full text-white !h-9`}
                      onClick={(e) => toggleDevice()}
                    >
                      <img
                        style={{ height: "24px", width: "24px" }}
                        alt="Switch Camera"
                        src={switchCamImg}
                      />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {/* ---- */}
            <div className="pt-2 ">
              <ButtonPrimary
                className="flex-1"
                onClick={() => {
                  if(typeof html5QrCode !== "undefined"){
                    html5QrCode
                    .stop()
                    .then((ignore: any) => {
                      // QR Code scanning is stopped.
                     window.location.href = "/digital-assets";
                     //const state = html5QrCode.getState();
                     //console.log('state', state)
                    })
                    .catch((err: any) => {
                      // Stop failed, handle it.
                      console.error(err);
                      window.location.href = "/digital-assets";
                    });
                  }
                }}
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M9.57 5.92993L3.5 11.9999L9.57 18.0699"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20.5 12H3.67004"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <span className="ml-2">Go Back Home</span>
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>

      <NcModal
        renderTrigger={() => null}
        isOpenProp={showModal}
        renderContent={renderContent}
        contentExtraClass="max-w-md"
        onCloseModal={() => setShowModal(false)}
        modalTitle="Scan Successful"
      />
      <ToastContainer />
    </div>
  );
};

export default Scan;
