import React, { FC, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Badge from "shared/Badge/Badge";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Handle from "model/Handle";
import { toast, ToastOptions, ToastContainer } from 'react-toastify';
import BackpacText from "components/Backpac/Text";
import {WaitlistSection1} from "./BuilderIO";
export interface WaitlistProps {
  className?: string;
}


const Waitlist: FC<WaitlistProps> = ({ className = "" }) => {

  const [email, setEmail] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [handle, setHandle] = useState("");
  const [loading, setLoading] = useState(false);
  const [handleExists, setHandleExists] = useState(false);

  useEffect(() => { 
  
   }, []);


   const toastConfig: ToastOptions = {
    position: "top-right",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
  }

  const checkHandle = async (handle: string) =>{
    const id = toast.loading("Checking Handle...", toastConfig);
    const _Handle = new Handle(null, handle, null, false);

    let exists = await _Handle.exists();

    if (exists === false) {
      setHandleExists(false);
      toast.update(id, {
        render: "Handle is Available",
        type: "info",
        isLoading: false,
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    } else {
      setHandleExists(true);
      toast.update(id, {
        render: `Handle ${handle} Exists`,
        type: "error",
        isLoading: false,
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    }
  }
  
  const handleSubmit = async (event: any) => {
    try {
      setLoading(true);
      event.preventDefault();

      const id = toast.loading("Joining...", toastConfig);
      const _handle = handle.replace('@','');

      const _Handle = new Handle('person', _handle, email, false);


       const form = document.getElementsByName('mc-embedded-subscribe-form')[0];
       form?.setAttribute('action', "https://xyz.us9.list-manage.com/subscribe/post?u=a01a88188438f5910d2410761&amp;id=aa46232ca3&amp;f_id=00ba1be1f0");
       form?.setAttribute('method', 'post');


       //check if the handle exists
       //const exists = await _Handle.exists();
       toast.update(id, { render: "Securing Handle", type: "info", isLoading: true });

       _Handle.save();

       toast.update(id, { render: "Handle Secured", type: "info", isLoading: false, position: "top-right",draggable: true,hideProgressBar: false, autoClose: 5000, theme: "colored", closeOnClick: true });

      
      setLoading(false);
      setEmail("");
      setFname("");
      setLname("");
      setCompanyName("");
      setHandle("");
      
      event.currentTarget.submit();

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>
          Join the BackPac™ Brands Waitlist for Direct Consumer Engagement
        </title>
        <meta
          name="description"
          content="Join the BackPac™ waitlist for brands and revolutionize your marketing strategy with direct, personalized engagement with your consumers. Sign up now."
        ></meta>
        <meta
          name="keywords"
          content="Brands, Consumer Engagement, Marketing, Waitlist, Direct Engagement, Personalized Engagement"
        ></meta>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <div className="container">
          <h1 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Join the BackPac™ Waitlist for Brands
          </h1>
          <div
            className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
            data-nc-id="SectionSubscribe2"
          >
            <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
            <WaitlistSection1 />
              {/* <h2 className="font-semibold text-3xl">
                Unlock the Power of Direct Consumer Engagement
                with BackPac™
              </h2>
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                By joining the BackPac™ Brands Waitlist, you'll be among the
                first to access this revolutionary marketing tool and unlock the
                power of direct consumer engagement. Sign up now and start
                building lasting relationships with your consumers today.
              </span>
              <ul className="space-y-4 mt-10">
                <li className="flex items-center space-x-4">
                  <Badge name="01" />
                  <span className="font-medium text-neutral-700 dark:text-neutral-300">
                  Direct, personalized engagement with your consumers
                  </span>
                </li>
                <li className="flex items-center space-x-4">
                  <Badge color="red" name="02" />
                  <span className="font-medium text-neutral-700 dark:text-neutral-300">
                  Increased engagement and brand loyalty
                  </span>
                </li>
                <li className="flex items-center space-x-4">
                  <Badge color="purple" name="03" />
                  <span className="font-medium text-neutral-700 dark:text-neutral-300">
                  Engage consumers in a 1-to-1 basis
                  </span>
                </li>
              </ul> */}
            </div>
            <div className="flex-grow">
              <div>
                <form
                  onSubmit={handleSubmit}
                  className="grid grid-cols-1 gap-6"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  target="_blank"
                >
                  <label className="block">
                    <Label>Brand <BackpacText /> Handle</Label>
                    <Input
                      name="HANDLE"
                      id="mce-HANDLE"
                      placeholder="@AcmeCo"
                      type="text"
                      required
                      className="mt-1"
                      value={handle}  
                      disabled={loading}
                      onChange={ e =>{
                        setHandle(e.target.value)
                      }}
                      onBlur={ e =>{ 
                        checkHandle(e.target.value.replace('@',''));
                      }}
                    />
                  </label>
                  <label className="block">
                    <Label>First Name</Label>
                    <Input
                      name="FNAME"
                      id="mce-FNAME"
                      placeholder="John"
                      type="text"
                      required
                      className="mt-1"
                      value={fname}
                      disabled={loading}
                      onChange={ e=>{
                        setFname(e.target.value)
                      }}
                    />
                  </label>
                  <label className="block">
                    <Label>Last Name</Label>
                    <Input
                      name="LNAME"
                      id="mce-LNAME"
                      placeholder="Smith"
                      type="text"
                      required
                      className="mt-1"
                      value={lname}
                      disabled={loading}
                      onChange={ e=>{
                        setLname(e.target.value)
                      }}
                    />
                  </label>
                  <label className="block">
                    <Label>Company Name</Label>
                    <Input
                      name="COMPANY"
                      id="mce-COMPANY"
                      required
                      type="text"
                      placeholder="Acme, Inc"
                      className="mt-1"
                      value={companyName}
                      disabled={loading}
                      onChange={ e=>{
                        setCompanyName(e.target.value)
                      }}
                    />
                  </label>
                  <label className="block">
                    <Label>Company Email</Label>

                    <Input
                      name="EMAIL"
                      id="mce-EMAIL"
                      required
                      type="email"
                      placeholder="john@acme.com"
                      className="mt-1"
                      value={email}
                      disabled={loading}
                      onChange={ e=>{
                        setEmail(e.target.value)
                      }}
                    />
                  </label>
                  <div style={{ marginTop: "1em" }}>
                    <ButtonPrimary type="submit" disabled={loading === true || handleExists === true ? true : false}>Join Waitlist</ButtonPrimary>
                  </div>
                  <div>
                    <input type="hidden" name="tags" value="12754037" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Waitlist;
