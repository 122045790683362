import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
//import ButtonPrimary from "shared/Button/ButtonPrimary";
//import ButtonSecondary from "shared/Button/ButtonSecondary";
//import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
//import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import AirTableBlog from "model/AirTableBlog";
import BlogPost from "interfaces/BlogPost";
import moment from 'moment';
import { type } from "os";
const BlogSingle = () => {
  const params = useParams();
  const [post, setPost] = useState<BlogPost>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getPost = async () => {
    const _AirtableBlog = new AirTableBlog();
    let post = await _AirtableBlog.getPost(params.blogId);

    if(Object.keys(post).length === 0){
      window.location.href = "/blog";
    }
    //Loop the post and find the featured
    setPost(post);
  };

  useEffect(() => {
    if (params !== null) {
      getPost();
    }
  }, [params]);

  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <Badge href="##" color="purple" name={post?.tag.toUpperCase()} />
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            {post?.title}
          </h1>
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
          <div dangerouslySetInnerHTML={{ __html: post?.description || "" }}></div>
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col items-center sm:flex-row sm:justify-between">
            <div className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
              <Avatar
                userName={post?.author}
                imgUrl={post?.avatar}
                containerClassName="flex-shrink-0"
                sizeClass="w-8 h-8 sm:h-11 sm:w-11 "
              />
              <div className="ml-3">
                <div className="flex items-center">
                    {post?.author}
                </div>
                <div className="text-xs mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    {moment(post?.date).format("dddd, MMM Do YYYY")}
                  </span>
                  <span className="mx-2 font-semibold">·</span>
                  {/* <span className="text-neutral-700 dark:text-neutral-300">
                    6 min read
                  </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-invert"
        dangerouslySetInnerHTML={{ __html: post?.content || "" }}
      ></div>
    );
  };

  const renderTags = () => {
    const tags = (typeof post?.metaKeywords !== "undefined" ? post?.metaKeywords.split(",") : [])
    return (
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        {tags.map((keyword: any, index: number) => (
          <a
            className="nc-Tag inline-block bg-white text-sm text-neutral-600 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
            href="##"
          >
            {keyword}
          </a>
        ))}
      </div>
    );
  };

  const renderAuthor = () => {
    return (
      <div className="max-w-screen-md mx-auto ">
        <div className="nc-SingleAuthor flex">
          <Avatar userName={post?.author}
                imgUrl={post?.avatar} sizeClass="w-11 h-11 md:w-24 md:h-24" />
          <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
            <span className="text-xs text-neutral-400 uppercase tracking-wider">
              WRITEN BY
            </span>
            <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
              <a href="/ncmaz/author/the-demo-author-slug">{post?.author}</a>
            </h2>
            {/* <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
              There’s no stopping the tech giant. Apple now opens its 100th
              store in China.There’s no stopping the tech giant.
              <a
                className="text-primary-6000 font-medium ml-1"
                href="/ncmaz/author/the-demo-author-slug"
              >
                Readmore
              </a>
            </span> */}
          </div>
        </div>
      </div>
    );
  };


  // const renderPostRelated = (_: any, index: number) => {
  //   return (
  //     <div
  //       key={index}
  //       className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
  //     >
  //       <Link to={"/blog-single"} />
  //       <NcImage
  //         className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
  //         src={_getImgRd()}
  //       />
  //       <div>
  //         <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
  //       </div>
  //       <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
  //         <Badge name="Categories" />
  //         <h2 className="block text-lg font-semibold text-white ">
  //           <span className="line-clamp-2">{_getTitleRd()}</span>
  //         </h2>

  //         <div className="flex">
  //           <span className="block text-neutral-200 hover:text-white font-medium truncate">
  //             {_getPersonNameRd()}
  //           </span>
  //           <span className="mx-1.5 font-medium">·</span>
  //           <span className="font-normal truncate">May 20, 2021</span>
  //         </div>
  //       </div>
  //       <Link to={"/blog-single"} />
  //     </div>
  //   );
  // };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>{post?.title}</title>
        <meta
          name="description"
          content={post?.metaDescription}
        ></meta>
        <meta
          name="keywords"
          content={post?.metaKeywords}
        ></meta>
      </Helmet>
      {renderHeader()}
      <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src={post?.featured}
      />

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        {renderTags()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {renderAuthor()}
      </div>
      {/* <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
        <div className="container ">
          <h2 className="text-3xl font-semibold">Related posts</h2>
          <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
            
            {[1, 1, 1, 1].filter((_, i) => i < 4).map(renderPostRelated)}
            
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default BlogSingle;