import Endpoint from "model/Endpoint";

export default class Handle {
  readonly type: string | null | undefined;
  readonly handle: string | null | undefined;
  readonly email: string | null | undefined;
  readonly confirmed: boolean | false;
  constructor(...args: [] | [type: string  | null | undefined, handle: string, email: string | null | undefined, confirmed: boolean | undefined]) {
    this.type = args[0] || undefined;
    this.handle = args[1];
    this.email = args[2] || undefined;
    this.confirmed = args[3] || false;
  }
  exists = async () => {
    const _Endpoint = new Endpoint();
    let res = await _Endpoint.get(`handle/exists/${this.handle}`);
    return res.data.data.exists;
  };
  save = async () => {
    const _Endpoint = new Endpoint();
    return await _Endpoint.post(`handle/save`, {
      type: this.type,
      email: this.email,
      handle: this.handle,
    });
  };
}